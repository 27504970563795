import React from 'react'
import Helmet from 'react-helmet'
import ogImage from '../../static/og-image.png'

export default ({ description, lang, meta, title }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: `https://www.brester.tech${ogImage}`,
        },
        {
          property: `og:image:width`,
          content: `500`,
        },
        {
          property: `og:image:height`,
          content: `1000`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'brester.tech',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta || [])}
    />
  )
}