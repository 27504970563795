import React from "react"
import styled from 'styled-components'


const Wrapper = styled.div`
  max-width: 1140px;
  margin: auto; 
  width: 100%; 
  display: flex; 
  flex-direction: column;
  align-content: strech; 
  min-height: 100%; 
  flex-grow: 1;
`

export default ({ children }) => {
  return (
    <Wrapper>
      { children }
    </Wrapper>
  )
}