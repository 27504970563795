import React from 'react'
import styled from 'styled-components'

const Heading = styled.h1`
  padding: 40px 0; 
  margin: 0;
  font-size: 60px;
  line-height: 80px; 
  text-align: center; 
  color: #fff;

  @media(min-width: 480px){
    font-size: 90px;
    line-height: 105px; 
  }
`

export default ({ children }) => <Heading>{ children }</Heading>