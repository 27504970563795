let rect;
let pixelRatio;

export default (canvas) => {
  rect = canvas.getBoundingClientRect()

  pixelRatio = window.devicePixelRatio;

  canvas.width = rect.width;
  canvas.height = rect.height; 

  const mouseCoordinates = {
    x: null, 
    y: null
  }

  canvas.addEventListener('mousemove', e => {
    mouseCoordinates.x = (e.clientX + rect.left);
    //flip the coordinate system
    mouseCoordinates.y = canvas.height - (e.clientY - rect.top);
  })

  canvas.addEventListener('mouseleave', e => {
    mouseCoordinates.x = null
    mouseCoordinates.y = null
  })

  const dotsStates = new Array(150).fill(null).map(item => {
    return {    
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      direction: Math.random() * 2 * Math.PI,
      speed: 1,
      curve: Math.random() * (Math.random() > 0.5 ? 0.01 : -0.01)
    }
  })

  const ctx = canvas.getContext('2d')

  requestAnimationFrame(animate)  

  function animate(){
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    drawDots(dotsStates, ctx, canvas)
    if(mouseCoordinates.x) drawLines(mouseCoordinates, dotsStates, ctx, canvas)
    if(mouseCoordinates.x) modifyDotsDirections(mouseCoordinates, dotsStates)

    requestAnimationFrame(animate)
  }
}

export let ResizeCanvas = (canvas) => {
  UpdateRect(canvas)

  canvas.width = rect.width;
  canvas.height = rect.height; 
}

export let UpdateRect = (canvas) => {
  rect = canvas.getBoundingClientRect();

  pixelRatio = window.devicePixelRatio;
}

function drawDots(dotsStates, ctx, canvas){
  dotsStates.forEach(state => {
    state = getDirection(state, canvas)

    state.x = state.x + Math.sin(state.direction) * state.speed;
    state.y = state.y + Math.cos(state.direction) * state.speed;
    
    ctx.strokeStyle ="#fff"
    ctx.fillStyle ="#fff"
    ctx.beginPath();
    ctx.arc(state.x, canvas.height - state.y, 1 / pixelRatio, 0, Math.PI * 2);
    ctx.stroke();
    ctx.fill()
  })
}

function getDirection(state, canvas){
  const directionQuadrant = getQuadrant(state.direction);

  if(
    (state.x <= 0 && ( directionQuadrant === 3 || directionQuadrant === 4)) || 
    (state.y >= canvas.height && (directionQuadrant === 1 || directionQuadrant === 4)) ||
    (state.x >= canvas.width && (directionQuadrant === 1 || directionQuadrant === 2)) ||
    (state.y <= 0 && (directionQuadrant === 2 || directionQuadrant === 3))
  ){
    let angle; 

    if(
      (state.y >= canvas.height && directionQuadrant === 4) ||
      (state.y <= 0 && directionQuadrant === 2) ||
      (state.x >= canvas.width && directionQuadrant === 1) || 
      (state.x <= 0 && directionQuadrant === 3)
    ){
      angle = -(state.direction % (Math.PI / 2) * 2) ;
    } else {
      angle = Math.PI - ((state.direction % (Math.PI / 2)) * 2);
      state.curve = -state.curve;
    }
    state.direction = modulo(state.direction + angle, (Math.PI * 2));
  } else if(Math.random() > 0.8 && getQuadrant(state.direction + state.curve) === directionQuadrant) {      
    state.direction =  state.direction + state.curve ;
  }

  return state;
}

function drawLines(mouseCoordinates, dotStates, ctx, canvas){
  dotStates.forEach(state => {
    if(isCloseToMouse(mouseCoordinates, state)){ 
        ctx.strokeStyle="rgba(255, 255, 255, 0.2)"
        ctx.beginPath()
        ctx.moveTo(state.x, canvas.height - state.y)
        ctx.lineTo(mouseCoordinates.x, canvas.height - mouseCoordinates.y)
        ctx.stroke()
    }
  })
}

function modifyDotsDirections(mouseCoordinates, dotStates){
  dotStates.forEach(state => {
    if(isCloseToMouse(mouseCoordinates, state)){ 
      let atan = Math.atan2(mouseCoordinates.x - state.x, mouseCoordinates.y - state.y);
      if(atan < 0){
        state.direction = Math.PI * 2 - Math.abs(atan);
      } else {
        state.direction = atan;
      }
    }
  })
}

function isCloseToMouse(mouseCoordinates, state){
  return Math.abs(state.x - mouseCoordinates.x) < 80 && Math.abs(state.y - mouseCoordinates.y) < 80
}

function modulo(x, mod){
  return ((x % mod) + mod) % mod
}

function getQuadrant(angle){
  return Math.ceil(angle / (Math.PI / 2))
}