import React from 'react'
import styled from 'styled-components'
import Paragraph from './Paragraph'

const Menu = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;

  & a {
    text-decoration: none;
    display: block; 
  }

  & ul {
    border-top: 1px solid #fff;
    display: flex;
    justify-content: space-evenly;
    list-style: none; 
    width: 100%;
    margin: 0;
    padding: 30px 0; 
    z-index: 2;
  }

  & li { 
    cursor: pointer;
  }
`

const items = [
  {
    title: 'Home', 
    href: '/'
  },
  {
    title: 'Projects', 
    href: '/projects'
  },
  {
    title: 'Awards', 
    href: '/awards'
  },
  {
    title: 'Contact', 
    href: '/contact'
  },
]

export default () => { 
  return (
    <Menu>
        <ul>
          { items.map((item, index) => {
            return(
              <li
                key={ index }
              >
                <a href={ item.href }>
                  <Paragraph>
                    { item.title }
                  </Paragraph>
                </a>
              </li>
            )
          })}
        </ul>
    </Menu>
  )
}

