import React from 'react'
import styled from 'styled-components'

const Paragraph = styled.p`
  font-size: 22px;
  line-height: 26px; 
  text-align: center; 
  color: #fff;
  margin: 0;

  @media(min-width: 480px){
    font-size: 28px;
    line-height: 32px; 
  }
`

export default ({ children }) => <Paragraph>{ children }</Paragraph>