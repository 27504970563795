import React from "react"
import styled from 'styled-components'
import Canvas from '../components/Canvas'

const Background = styled.div`
  min-height: 100vh;
  background: rgb(13,107,115);
  background: linear-gradient(180deg, rgba(13,107,115,1) 60%, rgba(243,122,95,1) 100%);
  display: flex; 
  flex-direction: column;
  position: relative; 
  padding: 0 20px; 
  overflow:hidden;
`

export default ({ children }) => {
  return (
    <Background>
      <Canvas />
      { children }
    </Background>
  )
}