import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import CanvasAnimation, { ResizeCanvas, UpdateRect } from './CanvasAnimation'

const Canvas = styled.canvas`
  position: absolute; 
  width: 100%; 
  height: 100%;
  top: 0; 
  left: 0;
  transition: opacity 200ms ease-out; 
`

export default () => {
  let canvasElement = useRef(null)

  useEffect(() => {
    CanvasAnimation(canvasElement.current)

    let timeout;

    window.addEventListener('resize', () => {
      canvasElement.current.style.opacity = 0;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        canvasElement.current.style.opacity = 1;
        ResizeCanvas(canvasElement.current)
      }, 250);
    }, [])

    let throttled = false;

    document.addEventListener('scroll', e => {
      if(throttled) return
      throttled = true;

      requestAnimationFrame(() => {
        UpdateRect(canvasElement.current)
        throttled = false;
      })
    })
  }, [])

  return (
    <Canvas
      ref={ canvasElement }
    ></Canvas>
  )
}
 